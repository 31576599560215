<template>
  <div>
    <div id="containerHeatmap" :style='`height: 100%; width: 100%`'>

    </div>
    <!-- Loading -->
    <div v-if="loading" style="
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    " class="text-center">
      <div>努力加载中...</div>
      <b-spinner label="Loading..." style="width: 4rem; height: 4rem" class="my-2"
                 variant="primary"/>

      <div class="text-muted">Loading ...</div>
    </div>

  </div>
</template>
<script>
import {Heatmap} from "@antv/g2plot";
import {momentJs} from "@/libs/utils/moment";
import {getDiagnosisGetRiskHeatmap} from "@/api/diagnosis";

export default {
  name: 'heatmap',
  props: {
    heatmapHeight: {
      default: 700
    },
  },
  mounted() {
    // this.initChart();
    this.doGet();
  },
  data: () => ({
    loading: false,
    filter: {
      time: momentJs().format(),
    },
  }),
  methods: {
    initChart(data) {
      const months = Array.from(new Set(data.map(item => Number(item.month.split('-')[1]))))
      const resultData = {} // key: month-day  value: data object
      const strYear = (new Date()).getFullYear()
      for (let month of months) {
        const strMonth = month < 10 ? '0' + month : month
        for (let day = 1; day <= new Date(2022, month, 0).getDate(); day++) {
          const strDay = day < 10 ? '0' + day : `${day}`
          resultData[`${strYear}-${strMonth}-${strDay}`] = {
            day: strDay,
            month: `${strYear}-${strMonth}`,
            time: `${strYear}-${strMonth}-${strDay}`,
            value: 0
          }
        }
      }
      for (let item of data) {
        resultData[item['time']] = item
      }
      const container = document.getElementById("containerHeatmap");
      const width = container.scrollWidth;
      const height = container.scrollHeight;
      const heatmapPlot = new Heatmap(
          document.getElementById("containerHeatmap"),
          {
            width,
            height: this.heatmapHeight,
            autoFit: false,
            data: Object.values(resultData),
            xField: "month",
            yField: "day",
            // xAxis: {
            //   visible: false,
            //   line: {
            //     visible: false
            //   }
            // },
            // yAxis: {
            //   visible: false,
            //   line: {
            //     visible: false
            //   }
            // },
            colorField: "value",
            legend: {
              visible: true,
            },
            // color: [
            //   "#174c83",
            //   "#7eb6d4",
            //   "#efefeb",
            //   "#efa759",
            //   "#9b4d16",
            // ],
            // meta: {
            //   "Month of Year": {
            //     type: "cat",
            //   },
            // },
          }
      );
      heatmapPlot.render();
    },
    doGet() {
      this.loading = true
      const timeArr = this.filter.time
          .replaceAll(" 至 ", ",")
          .replaceAll(" to ", ",")
          .split(",");
      const start = momentJs(timeArr[0])
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      const end = momentJs(timeArr[1] || timeArr[0])
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
      getDiagnosisGetRiskHeatmap().then((res) => {
        this.initChart(res.data);
      }).finally(() => {
        this.loading = false
      });
    },
  },
}
</script>
